

/*.wrapper {
    border-radius: 5px;
    border: 1px solid #ccc;
}

    .wrapper .text-input {
        display: flex;
        border-bottom: 1px solid #ccc;
    }

.text-input .to-text {
    border-radius: 0px;
    border-left: 1px solid #ccc;
}

.text-input textarea {
    height: 250px;
    width: 100%;
    border: none;
    outline: none;
    resize: none;
    background: none;
    font-size: 18px;
    padding: 10px 15px;
    border-radius: 5px;
}

    .text-input textarea::placeholder {
        color: #b7b6b6;
    }

.controls, li, .icons, .icons i {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.controls {
    list-style: none;
    padding: 12px 15px;
}

    .controls .row .icons {
        width: 38%;
    }

        .controls .row .icons i {
            width: 50px;
            color: #adadad;
            font-size: 14px;
            cursor: pointer;
            transition: transform 0.2s ease;
            justify-content: center;
        }

    .controls .row.from .icons {
        padding-right: 15px;
        border-right: 1px solid #ccc;
    }

    .controls .row.to .icons {
        padding-left: 15px;
        border-left: 1px solid #ccc;
    }

    .controls .row select {
        color: #333;
        border: none;
        outline: none;
        font-size: 18px;
        background: none;
        padding-left: 5px;
    }

.text-input textarea::-webkit-scrollbar {
    width: 4px;
}

.controls .row select::-webkit-scrollbar {
    width: 8px;
}

.text-input textarea::-webkit-scrollbar-track,
.controls .row select::-webkit-scrollbar-track {
    background: #fff;
}

.text-input textarea::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 8px;
}

.controls .row select::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 8px;
    border-right: 2px solid #ffffff;
}

.controls .exchange {
    color: #adadad;
    cursor: pointer;
    font-size: 16px;
    transition: transform 0.2s ease;
}

.controls i:active {
    transform: scale(0.9);
}

.container button {
    width: 100%;
    padding: 14px;
    outline: none;
    border: none;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
    font-size: 17px;
    border-radius: 5px;
    background: #5372F0;
}

@media (max-width: 660px) {
    .container {
        padding: 20px;
    }

    .wrapper .text-input {
        flex-direction: column;
    }

    .text-input .to-text {
        border-left: 0px;
        border-top: 1px solid #ccc;
    }

    .text-input textarea {
        height: 200px;
    }

    .controls .row .icons {
        display: none;
    }

    .container button {
        padding: 13px;
        font-size: 16px;
    }

    .controls .row select {
        font-size: 16px;
    }

    .controls .exchange {
        font-size: 14px;
    }
    
}*/
.clstransulate{
    height:150px !important;
}
.form-roundedbtn2 {
    border-radius: 2rem !important;
    padding: 7px 8px !important;
    width: 110px !important;
    background: #8e2e18 !important;
    border: none !important;
    font-weight: bold !important; 
     margin: 10px 10px 10px 0 !important; 
    font-size: 13px !important;
}
.buttonW {
    width: 160px !important;
}