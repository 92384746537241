.form-roundedbtn3 {
    border-radius: 2rem !important;
    padding: 7px 8px !important;
    width: 110px !important;
    background: #198754 !important;
    border: none !important;
    font-weight: bold !important;
    margin: 10px 10px 10px 0 !important;
    font-size: 13px !important;
}

.form-roundedbtn5 {
    border-radius: 2rem !important;
    padding: 7px 8px !important;
    width: 110px !important;
    background: #198754 !important;
    border: none !important;
    font-weight: bold !important;
    margin: 0px 10px 10px 0 !important;
    font-size: 13px !important;
}
.form-roundedbtn2 {
    border-radius: 2rem !important;
    padding: 7px 8px !important;
    width: 110px !important;
    background: #8e2e18 !important;
    border: none !important;
    font-weight: bold !important;
    margin: 10px 10px 10px 0 !important;
    font-size: 13px !important;
}
.form-roundedbtnsupplier {
    border-radius: 2rem !important;
    padding: 7px 8px !important;
    width: 130px !important;
    background: #198754 !important;
    border: none !important;
    font-weight: bold !important;
    margin: 10px 10px 10px 0 !important;
    font-size: 13px !important;
}

.form-roundedbtn4 {
    
    border-radius: 2rem;

    padding: 10px 14px;
    width: 130px;
    background: #198754;
    border: none;
    font-weight: bold;
    margin: 35px 10px 10px 0;
    
    font-size: 15px;
}
.form-roundedbtn {
	border-radius: 2rem;
	padding:10px 14px;
	width:130px;
	background:#198754;
	border:none;
	font-weight:bold;
    margin: 0px 10px 10px 0 !important;
	font-size:15px;
}
.form-roundedsubmitbtn {
	border-radius: 2rem;
	padding:10px 14px;
	width:130px;
	background:#8e2e18;
	border:none;
	font-weight:bold;
    margin: 0px 10px 10px 0 !important;
	font-size:15px;
}
 col .pt-2 {
    padding-bottom: 6px;
    font-weight: normal;
}
.buttonW
{
  width:160px !important;
}
/* .ag-border {    
    --ag-borders:solid 1px;   
} */

.buttonWidth{
    width:180 !important;

}
.form-btnapprove {
    border-radius: 2rem !important;
	padding:10px 14px;
    width: 130px !important;
    background: #198754 !important;
    border: none !important;
    font-weight: bold !important;
    margin: 0px 10px 10px 0 !important;
    font-size: 15px !important;
}
.form-btnverify {
    border-radius: 2rem !important;
	padding:10px 14px;
    width: 150px !important;
    background: #198754 !important;
    border: none !important;
    font-weight: bold !important;
    margin: 0px 10px 10px 0 !important;
    font-size: 15px !important;
}
.form-btnreject {
    border-radius: 2rem !important;
	padding:10px 14px;
    width: 130px !important;
    background: #8e2e18 !important;
    border: none !important;
    font-weight: bold !important;
    margin: 0px 10px 10px 0 !important;
    font-size: 15px !important;
}
.form-btncall {
    border-radius: 2rem !important;
	padding:10px 14px;
    width: 170px !important;
    background: #f4c81a !important;
    border: none !important;
    font-weight: bold !important;
    margin: 0px 10px 10px 0 !important;
    font-size: 15px !important;
}

.simple-input-editor {
    width: 100%;
    height: 100%;
    background-color: none;
    border-color: transparent;
    border:none;
    outline: none;
    
  }
  .ag-header-cell {
    
    border: 1px solid rgb(226, 224, 224);
}

.ag-cell-border{
    border: 1px solid rgb(241, 237, 237);
}
.ag-ltr .ag-cell {
    border-right-width: 1px;
    border-left: 1px solid rgb(226, 224, 224);
    border-right: 1px solid rgb(226, 224, 224);
    border-bottom: 1px solid rgb(226, 224, 224);
    font-weight: normal;
    color: #515151;
}

.ag-header-cell-text {
    font-weight: normal;
    color: #515151;
}
@media (max-width: 765px)
{
.col .btn.btnclose-x {
    /*margin-left: 45%;*/
    margin-left: 90%;
    /* margin-right: 10px; */
}
}
.btnclose-x {
    margin-left: 95%;
    /*margin-right: 90px;*/
    padding: 0.5rem 1rem;

}

/* .ag-data-color{
    font-weight: normal;
    color: #515151;
} */

/*.nav-tabs-custom .nav-item .nav-link.active:after {
    -webkit-transform: scale(1);
    transform: scale(1);
}
.nav-tabs-custom .nav-item .nav-link::after {
    content: "";
    background: #f46a6a;
}
.nav-tabs-custom .nav-item .nav-link::after {
    content: "";
    background: #f46a6a;
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -1px;
    -webkit-transition: all 250ms ease 0s;
    transition: all 250ms ease 0s;
    -webkit-transform: scale(0);
    transform: scale(0);
}
*/
.form-roundedbtn-popup {
    border-radius: 2rem;
    padding: 10px 14px;
    width: 130px;
    background: #8e2e18 !important;
    border: none;
    font-weight: bold;
    margin: 0px 10px 10px 0 !important;
    font-size: 15px;
}