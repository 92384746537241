@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
.login-logo, .form-container {
	margin: 0 auto;
	max-width: 31em;
	transition: all 0.5s ease-in;
}
.login-logo {
	padding: 10% 1em 1em 1em;
	text-align:center;
}
.form-rounded {
	border-radius: 2rem;
	padding:15px 19px
}
.form-roundedbtn {
	border-radius: 2rem;
	padding:15px 19px;
	width:200px;
	background:#8e2e18;
	border:none;
	font-weight:bold;
	margin:35px 0 0 0;
}
.form-container {
	background: white;
	padding: 1.5em 3.5em;
	/* border-radius: 1em; */
    box-shadow: 0px 0px 0.4em #ddd;
}
.form-container h2 {
	margin-top: 0;
	color: #22678e;
	font-family:'Overpass', sans-serif;
	font-size: 2.2rem;
}
.form-container .error {
	color: red;
	padding-left: 16px;
	font-size: 13px;
}
.form-container label span {
	color: #d9534f;
}
.form-container button {
	background: #8e2e18;
}
.form-container button:hover {
	background: #22678e;
}
.form-signin label {
	padding:3px 0 3px 15px;
}
.form-signin .form-group {
	margin:17px 0 7px 0;
}
html {
	box-sizing: border-box;
}
*, :before, :after {
	box-sizing: inherit;
}
body {
	background: #ffffff;
	margin: 1em;
	font-family: "Open Sans", sans-serif;
}
.radio-btn input[type="radio"], .check-box input[type="checkbox"] {
	visibility: hidden;
}
/*Custom checkbox*/
 .check-box {
	width: 17px;
	height: 17px;
	cursor: pointer;
	display: inline-block;
	margin: 2px 7px 0 0;
	position: relative;
	overflow: hidden;
	box-shadow: 0 0 1px #ccc;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	/*background: rgb(255, 255, 255);
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(47%, rgba(246, 246, 246, 1)), color-stop(100%, rgba(237, 237, 237, 1)));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
    background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ededed', GradientType=0);*/
    border: 1px solid #ccc;
}
.check-box i {
	background: #22678e;
	position: absolute;
	left: 2px;
	bottom: -15px;
	width: 11px;
	height: 11px;
	opacity: 1;
}
.checkedBox {
/* -moz-box-shadow: inset 0 0 5px 1px #ccc;
    -webkit-box-shadow: inset 0 0 5px 1px #ccc;
    box-shadow: inset 0 0 5px 1px #ccc;
    border-bottom-color: #fff;*/
}
.checkedBox i {
	bottom: 2px;
	-webkit-transform:rotateZ(0deg);
	-moz-transform:rotateZ(0deg);
	-o-transform:rotateZ(0deg);
	transform:rotateZ(0deg);
}
/*Custom radio button*/
 .radio-btn {
	width: 20px;
	height: 20px;
	display: inline-block;
	float: left;
	margin: 3px 7px 0 0;
	cursor: pointer;
	position: relative;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	border-radius: 100%;
	border: 1px solid #ccc;
	box-shadow: 0 0 1px #ccc;
	background: rgb(255, 255, 255);
	background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(47%, rgba(246, 246, 246, 1)), color-stop(100%, rgba(237, 237, 237, 1)));
	background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
	background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
	background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
	background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
 filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ededed', GradientType=0);
}
.checkedRadio {
	-moz-box-shadow: inset 0 0 5px 1px #ccc;
	-webkit-box-shadow: inset 0 0 5px 1px #ccc;
	box-shadow: inset 0 0 5px 1px #ccc;
}
.radio-btn i {
	border: 1px solid #E1E2E4;
	width: 10px;
	height: 10px;
	position: absolute;
	left: 4px;
	top: 4px;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	border-radius: 100%;
}
.checkedRadio i {
	background-color: #898A8C;
}
.font12 {
	font-size:12px;
}
@media (max-width: 576px) {
.form-container {
 background: white;
 padding: 1.5em 1.5em;
    /* border-radius: 1em; */
    box-shadow: 0px 0px 0.4em #ddd;
}
.login-logo img {
width:100%;
}
}
