@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
.profile-menu .dropdown-menu {
	right: 0;
	left: unset;
}
.profile-menu .fa-fw {
	margin-right: 10px;
}
 .toggle-change::after {
 border-top: 0;
 border-bottom: 0.3em solid;
}
#sidebar {
	padding: 0 15px 15px 15px;
	margin-top:0px;
	border-right:1px solid #ccc;height: 100% !important;
}
.sidebar-nav .nav {
	width:88%;
	margin:auto
}
body {
	background: #ffffff;
	margin: 0em;
	font-family: "Open Sans", sans-serif;
}
.form-control {
	width: 89%;
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}.form-roundedbtn2 {
    border-radius: 1rem;
    padding: 7px 10px;
    width: 130px;
    background: #8e2e18;
    border: none;
    font-weight: bold;
     margin: 10px 0 0 0; 
    font-size: 12px;
}
.form-roundedbtn3 {
    border-radius: 1rem;
    padding: 7px 10px;
    width: 130px;
    background: #198754;
    border: none;
    font-weight: bold;
     margin: 10px 0 0 0; 
    font-size: 12px;
}.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #7B1FA2;
}

.custom-control-input:checked~.custom-control-label.red::before {
  background-color: red;
}

.custom-control-input:checked~.custom-control-label.green::before {
  background-color: green;
}
.dropdown-item {
	font-size:12px;
}
.sidebar-nav .nav li {
	background:#21a7d0;
	color:#fff;
	width: 100%;
	padding: 11px 39px;
}
.sidebar-nav .nav li a {
	font-size:16px;
	color:#fff;
	text-decoration:none;font-weight: bold;
}
.navbar {
	border-bottom:1px solid #ccc;
	padding:0;
}
.navbar-toggler {
	padding: 0;
}
.formsec {
	width:70%;
	padding-left:12%;
	padding-top:65px !important;
}
.formsec.dashboard {
	width:auto;
	padding-left:12%;
		padding-right:12%;
	padding-top:3%;
}
.radio-btn input[type="radio"], .check-box input[type="checkbox"] {
	visibility: hidden;
}
/*Custom checkbox*/
 .check-box {
	width: 17px;
	height: 17px;
	cursor: pointer;
	display: inline-block;
	margin: 2px 7px 0 0;
	position: relative;
	overflow: hidden;
	box-shadow: 0 0 1px #ccc;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	/*background: rgb(255, 255, 255);
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(47%, rgba(246, 246, 246, 1)), color-stop(100%, rgba(237, 237, 237, 1)));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
    background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ededed', GradientType=0);*/
    border: 1px solid #ccc;
}
.check-box i {
	background: #22678e;
	position: absolute;
	left: 2px;
	bottom: -15px;
	width: 11px;
	height: 11px;
	opacity: 1;
}
.check-box1 {
	width: 17px;
	height: 17px;
	cursor: pointer;
	display: inline-block;
	margin: 2px 7px 0 0;
	position: relative;
	overflow: hidden;
	box-shadow: 0 0 1px #ccc;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	/*background: rgb(255, 255, 255);
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(47%, rgba(246, 246, 246, 1)), color-stop(100%, rgba(237, 237, 237, 1)));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
    background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ededed', GradientType=0);*/
    border: 1px solid #ccc;
}
.check-box1 i {
	background: #22678e;
	position: absolute;
	left: 2px;
	bottom: -15px;
	width: 11px;
	height: 11px;
	opacity: 1;
}
.check-box2 {
	width: 17px;
	height: 17px;
	cursor: pointer;
	display: inline-block;
	margin: 2px 7px 0 0;
	position: relative;
	overflow: hidden;
	box-shadow: 0 0 1px #ccc;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	/*background: rgb(255, 255, 255);
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(47%, rgba(246, 246, 246, 1)), color-stop(100%, rgba(237, 237, 237, 1)));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
    background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ededed', GradientType=0);*/
    border: 1px solid #ccc;
}
.check-box2 i {
	background: #22678e;
	position: absolute;
	left: 2px;
	bottom: -15px;
	width: 11px;
	height: 11px;
	opacity: 1;
}

.checkedBox i {
	bottom: 2px;
	-webkit-transform:rotateZ(0deg);
	-moz-transform:rotateZ(0deg);
	-o-transform:rotateZ(0deg);
	transform:rotateZ(0deg);
}
/*Custom radio button*/
 .radio-btn {
	width: 20px;
	height: 20px;
	display: inline-block;
	/* float: left; */
	margin: 3px 7px 0 0;
	cursor: pointer;
	position: relative;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	border-radius: 100%;
	border: 1px solid #ccc;
	box-shadow: 0 0 1px #ccc;
	background: rgb(255, 255, 255);
	background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(47%, rgba(246, 246, 246, 1)), color-stop(100%, rgba(237, 237, 237, 1)));
	background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
	background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
	background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
	background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
 filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ededed', GradientType=0);
}
.checkedRadio {
	-moz-box-shadow: inset 0 0 5px 1px #ccc;
	-webkit-box-shadow: inset 0 0 5px 1px #ccc;
	box-shadow: inset 0 0 5px 1px #ccc;
}
.radio-btn i {
	border: 1px solid #E1E2E4;
	width: 10px;
	height: 10px;
	position: absolute;
	left: 4px;
	top: 4px;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	border-radius: 100%;
}
.checksection {
	font-size:12px;
}
.form-roundedbtn {
	border-radius: 2rem;
	padding:10px 14px;
	width:130px;
	background:#8e2e18;
	border:none;
	font-weight:bold;
	margin:35px 0 0 0;
	font-size:15px;
}
.fixed-table-container{
border:none;}.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}
.checkedRadio i {
	background-color: #22678e;
}
.userpic {
	background:#000;
	border-radius: 30px;
	height: 30px;
	width: 30px;
	display: inline-block;
	margin-right: 8px;
	top: -9px;
	position: relative;
}
.username {
	font-weight:normal;
	color:#21a7d0;
	font-size:15px;
	display:inline-block;
}
.sub {
	color:#8c8c8c;
	font-size:11px;
	display:block;
}
#formFileMultiple {
	background: #22678e;
	color: #fff;
}
a.delete{
font-weight:bold;
color:#FF0000;
font-size:20px;
cursor:pointer;
text-decoration:none}.bootstrap-table .table thead>tr>th {
    padding: 8px;
    margin: 0;
}
.form-check-inline {
	padding-left:15px;
}
.formadduser {
	margin-top:16px;
}
.formadduser .form-group {
	margin:14px 0px 14px 0px;
	font-weight:bold;
	color:#515151;
}
.formadduser .form-group label {
	padding-bottom:6px;
}.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 242px !important;
    height: 46px;
				}.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    overflow: hidden;
    font-size: 13px;
}.bootstrap-select > .dropdown-toggle {
    position: relative;
    width: 100%;
    z-index: 1;
    text-align: right;
    white-space: nowrap;
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 10px;
    padding-top: 10px;
}
.w-23{
width:23%;}
.heading {
	font-weight:bold;
	color:#22678e;
	margin-bottom:23px;    font-size: 28px;
}
 @media (max-width: 576px) {
.sidebar-nav .nav {
width:100%;
}
.formsec {
width:98%;
padding-left:2%;
}#sidebar {
	padding: 0 15px 15px 15px;
	margin-top:-80px !important;
	border-right:1px solid #ccc;height: auto;
}.formsec.dashboard {
    width: auto;
    padding-left: 0%;
     padding-right: 0;
    padding-top: 3%;
}
}




.flip {
  -webkit-perspective: 800;   
          perspective: 800;
        position: relative;
        text-align: center;
}
.flip .card.flipped {
  -webkit-transform: rotatey(-180deg);
          transform: rotatey(-180deg);
}
 .card {
    width: 100%;
    height: 110px;
    -webkit-transform-style: preserve-3d;
    -webkit-transition: 0.5s;
    transform-style: preserve-3d;
    transition: 0.5s;
    background: rgb(33,39,47);
background: linear-gradient(90deg, rgba(33,39,47,1) 0%, rgba(52,80,95,1) 100%);
   
}
.flip .card .face {
  -webkit-backface-visibility: hidden ;
    backface-visibility: hidden ;
   z-index: 2;
}
.flip .card .front {
   position: absolute;
   width: 100%;
   z-index: 1;
}
.flip .card .front img{
  width: 100%;
  height: 100%;
}
.card-img, .card-img-bottom, .card-img-top {
   
  /* width: 52px;
   height: 52px;*/
    margin: 10px 0 10px 0;
				width:auto;
}
.flip .card .back {
  padding-top: 10%;
  -webkit-transform: rotatey(-180deg);
          transform: rotatey(-180deg);
  position: absolute;
}
.inner{
  margin:0px !important;
  width: 100%;
}
.inner h3{
font-size:12px; color:#ffffff;}
.navbar_full li {
	cursor: pointer;
}

/* */

.navbar_mob{
	display: none;
  }
  .container-fluid-mob{
	display: none;
  }
  @media (max-width:770px) and (min-width:575px) {
    .navbar_full{
		margin-top: 80px;
	  }
	  .blue-heading{
		margin-top: -21px;
	  }
	  .formsec.dashboard{
		padding-top: 18% !important;
	  }
	  
	  
}
@media (max-width:992px) and (min-width:575px) {
   
	.navbar-expand-lg .navbar-collapse{
		display: -webkit-inline-box !important;
    margin-top: -50px;	
	}
	  
}
@media (max-width: 575px) {
  .navbar_full{
	display: none;
  }
  .navbar_mob{
	margin-top: 80px;
	display: block;
  }
  .navbar_mob li {
	cursor: pointer;
	list-style: none;
	background: linear-gradient(90deg, rgba(33,39,47,1) 0%, rgba(52,80,95,1) 100%);
    color: #fff;
    width: auto;
    padding: 5px 10px;
    border-bottom: 1px solid #fff;
}
.navbar_mob li a {
	font-size:16px;
	color:#fff;
	text-decoration:none;font-weight: bold;
}
  .desktop{

	display: none;
  }
  .container-fluid-mob{
	display: block;
	padding-top: 10px;
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important
  }
}
.dropdown {
	position: relative;
	display: inline-block;
  }
  
  .dropdown-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 160px;
	width: 385px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	padding: 12px 16px;
	z-index: 1;
	height: 550px;
	overflow:hidden; 
	overflow-y:scroll;
  }
  
  /* .dropdown:hover .dropdown-content {
	display: block;
  } */

  .avtar_logout {
	position: relative;
	display: inline-block;
	left: 90%;
	top:4vh
  }
  
  .avtar-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	padding: 12px 16px;
	z-index: 1;
	right: 1vh;
  }
  
  .avtar_logout:hover .avtar-content {
	display: block;
  }