.form-roundedbtn3 {
    border-radius: 2rem !important;
    padding: 7px 8px !important;
    width: 110px !important;
    background: #198754 !important;
    border: none !important;
    font-weight: bold !important;
    margin: 10px 10px 10px 0 !important;
    font-size: 13px !important;
}

.form-roundedbtn5 {
    border-radius: 2rem !important;
    padding: 7px 8px !important;
    width: 110px !important;
    background: #198754 !important;
    border: none !important;
    font-weight: bold !important;
    margin: 0px 10px 10px 0 !important;
    font-size: 13px !important;
}

.form-roundedbtn2 {
    border-radius: 2rem !important;
    padding: 7px 8px !important;
    width: 110px !important;
    background: #8e2e18 !important;
    border: none !important;
    font-weight: bold !important;
    margin: 10px 10px 10px 0 !important;
    font-size: 13px !important;
}


.form-roundedbtn4 {

    border-radius: 2rem;

    padding: 10px 14px;
    width: 130px;
    background: #198754;
    border: none;
    font-weight: bold;
    margin: 35px 10px 10px 0;

    font-size: 15px;
}

.form-roundedbtn {
    border-radius: 2rem;
    padding: 10px 14px;
    width: 130px;
    background: #198754;
    border: none;
    font-weight: bold;
    margin: 0px 10px 10px 0 !important;
    font-size: 15px;
}

.form-roundedsubmitbtn {
    border-radius: 2rem;
    padding: 10px 14px;
    width: 130px;
    background: #8e2e18;
    border: none;
    font-weight: bold;
    margin: 0px 10px 10px 0 !important;
    font-size: 15px;
}

col .pt-2 {
    padding-bottom: 6px;
    font-weight: normal;
}

.buttonW {
    width: 160px !important;
}

/* .ag-border {    
    --ag-borders:solid 1px;   
} */
.form-btnapprove {
    border-radius: 2rem !important;
    padding: 10px 14px;
    width: 130px !important;
    background: #198754 !important;
    border: none !important;
    font-weight: bold !important;
    margin: 0px 10px 10px 0 !important;
    font-size: 15px !important;
}

.form-btnreject {
    border-radius: 2rem !important;
    padding: 10px 14px;
    width: 130px !important;
    background: #8e2e18 !important;
    border: none !important;
    font-weight: bold !important;
    margin: 0px 10px 10px 0 !important;
    font-size: 15px !important;
}

.simple-input-editor {
    width: 100%;
    height: 100%;
    background-color: none;
    border-color: transparent;
    border: none;
    outline: none;

}

.ag-header-cell {

    border: 1px solid rgb(226, 224, 224);
}

.ag-cell-border {
    border: 1px solid rgb(241, 237, 237);
}

.ag-ltr .ag-cell {
    border-right-width: 1px;
    border-left: 1px solid rgb(226, 224, 224);
    border-right: 1px solid rgb(226, 224, 224);
    border-bottom: 1px solid rgb(226, 224, 224);
    font-weight: normal;

    color: #515151;
}

.ag-header-cell-text {
    font-weight: normal;
    color: #515151;
}

/* .react-datepicker__time-box
{
    width:100px;
}
.react-datepicker__time-list{
    width:100px;
}
.react-datepicker__time-container 
{ width:120px;

} */
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    /* width: 85px; */
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
    border-bottom-right-radius: 0.3rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    list-style: none;
    margin: 0;
    height: calc(195px + (1.7rem / 2));
    overflow-y: scroll;
    padding-right: 0;
    padding-left: 0;
    width: 120%;
    box-sizing: content-box;
}

.react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    align-content: center;
    margin-top: 10px;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 0.3rem;
    height: 64px;
}

.checkposition {
    padding-right: 20px;
    padding-bottom: 15px;
}

.formdata-check {
    border: 1px solid gray;
    padding: 5%;
    margin: auto;
    width: 90%;
}

/*@media (max-width: 765px)
{
.col .btn.btnclose-x {
    margin-left: 45%;
    /* margin-right: 10px; */
/*}
};
.btnclose-x {
    margin-left: 95%;
    /*margin-right: 90px;*/
/*padding: 0.5rem 1rem;

/*}/*style={{ marginRight: 90 }}
/* .ag-data-color{
    font-weight: normal;
    color: #515151;
} */
@media (max-width: 765px) {
    .col .btn.btnclose-x {
        /*margin-left: 45%;*/
        margin-left: 90%;
        /* margin-right: 10px; */
    }
}

.btnclose-x {
    margin-left: 95%;
    /*margin-right: 90px;*/
    padding: 0.5rem 1rem;

}

.form-feedback {
    border-radius: 2rem !important;
    padding: 10px 14px;
    width: 180px !important;
    background: #198754 !important;
    border: none !important;
    font-weight: bold !important;
    margin: 0px 10px 10px 0 !important;
    font-size: 15px !important;
}

.checkposition {
    padding-right: 20px;
    padding-bottom: 15px;
    /*margin: 14px 0px 14px 0px;*/
    font-size: 15px;
    font-weight: normal;
    color: #515151;
}

.form-group-x {
    margin: 14px 0px 14px 0px;
    font-weight: normal;
    color: #515151;

    font-family: "Open Sans";
}
.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.2);
      
  }