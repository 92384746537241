@media (min-width: 1025px) and (max-width: 1280px) {
}

@media (max-width: 1200px) {
    td.chatMsgContent .Content {
        max-width: 700px;
    }

    span.chatHeaderText{
        max-width: 540px;
    }

}

@media (max-width: 990px) {
    td.chatMsgContent .Content {
        max-width: 475px;
    }

    span.chatHeaderText{
        max-width: 315px;
    }

    .card.SPDetails {
        flex-wrap: wrap;
    }

    .SPDetailsContainer {
        padding: 24px;
    }

    .SPDetails .SPNameWidget {
        width: 420px;
    }

    .SPDetails .SPdpWidget {
        width: 220px;
    }

    .SPDetails .SPAddressWidget {
        width: 100%;
    }

    .SPAddressWidget {
        display: flex;
        flex-direction: row;
        border-top: 1px solid #e1e1e1;
        border-left: 0;
    }

    .SPAddressContent {
        width: 100%;
        margin: 0;
    }

    .SPAddressContent:first-child {
        margin: 0 15px 0 0;
    }

    .SPAvailContainer {
        width: 190px;
        margin-bottom: 15px;
    }

    .SPWorkYear {
        margin-right: 20px;
        width: 160px;
        text-align: right;
    }

    .SPEducationItems .SPWorkYear{
        width: 96px;
    }

    .SPCertificateHeader{
        margin-right: 15px;
    }

    .ratePerHour{
        margin-right: 40px;
        padding: 0 5px;
        font-size: 22px;
    }

    .ratePerHour span:before{
        font-size: 18px;
    }

    .SPName{
        font-size: 22px;
    }

    .SPName:after{
        height: 20px;
        width: 20px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .sideNavigation li {
        margin: 5px 0 !important;
    }

    .sideContent {
        width: 380px;
    }

    div.mainWidgetOnboarding{
        width: calc(100% - 380px);
    }

}

@media (max-width: 768px) {

    div.mainWidgetOnboarding {
        width: 100%;
    }


    .slightTopview {
margin: 0 20px;
}

    .newConvText{
        display: none !important
    }

    span.chatHeaderText{
        max-width: 180px;
    }

    .navbar {
        position: fixed !important;
        height: 60px !important;
        width: 100%;
        z-index: 99;
        background-color: #ffffff;
    }

    .navbar-brand {
        color: #3c1053;
    }

    .navbar-collapse {
        z-index: 2;
    }

    .mainWidget {
        min-height: calc(100vh - 140px);
    }

    .mainContent {
        margin-top: 140px;
        height: calc(100vh - 210px);
    }

    .sideContent {
        position: absolute;
        top: 58px;
        height: 80px;
        width: 100%;
        z-index: 1;
    }

    .sideNavigation {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .sideNavigation li {
        position: relative;
        height: 80px;
        width: 100%;
    }

    .sideNavigation li:before {
        bottom: -20px;
        left: 0;
        margin: 0 auto;
        right: 0;
    }

    .sideNavigation li h5 {
        position: fixed;
        font-size: 18px;
        width: calc(100% - 60px);
        opacity: 0;
        left: 30px;
        margin-top: 10px;
    }

    .sideNavigation li.active h5 {
        opacity: 1;
    }

    .sideNavigation li p {
        display: none;
    }

    .participantsSearchForm {
        height: 100%;
        padding: 0 10px 0 10px !important;
    }

    input.form-control {
        width: 100%;
    }

    .mainContent .row > div > h4 {
        display: none;
    }

    .receivePass {
        width: calc(100% - 60px);
    }

    .ContentCheckbox {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .ContentCheckbox label:before {
        top: 40px;
        right: 15px;
    }

    .navbar-toggler:focus {
        outline: 0;
    }

    .btn {
        padding: 5px 14px !important;
    }

    .mainWidgetProfile {
        margin-top: 60px;
    }

    .newConversationBtn {
        position: relative;
        border-radius: 50% !important;
        color: #3c1053 !important;
        height: 32px;
        width: 32px;
        margin-right: 20px;
        overflow: hidden;
    }

    .newConversationBtn:before {
        content: "+";
        position: absolute;
        color: #fff !important;
        height: 100%;
        width: 100%;
        right: 0;
        left: 0;
        margin: 0 auto;
        font-size: 32px;
        top: 12%;
        line-height: 22px;
    }

    .pageTitle {
        padding: 5px 0 5px 20px;
        margin-bottom: 2px;
    }

    .avatarWidget {
        width: 85px !important;
    }

    .MsgCount {
        width: 96px !important;
    }

    .MsgThreadContent {
        padding: 0 5px !important;
    }

    .avatarContainer {
        width: 24px !important;
    }

    .avatarImage {
        width: 32px !important;
        height: 32px !important;
    }

    .totalMembers {
        min-width: 32px !important;
    }

    .participantsWidget {
        position: absolute;
        left: 100%;
        border: 1px solid #cccccc;
        width: 100% !important;
    }

    .participantsWidget.true {
        left: 0;
    }

    a.showParticipantList {
        display: block !important;
    }

    .chatContainerWrapper {
        height: calc(100vh - 128px) !important;
    }

    .participantsList .Content {
        height: calc(100vh - 245px) !important;
        padding: 0 !important;
    }

    .participantsList{
        width: 100%;
    }

    .table td.leaveConversation, .table td.AddParticipantsBtn {
        height: 64px;
    }

    a.chatBackButton:before, a.editButton:before {
        top: 2px;
        -webkit-background-size: 20px;
        background-size: 20px;
    }

    td.chatMsgContent .Content {
        max-width: 360px;
        height: calc(100vh - 253px);
    }

    td.chatMsgContent .Content.ImgUploaded {
        height: calc(100vh - 420px);
    }

    .chatMessage {
        max-width: 100% !important;
    }

    .Content {
        padding: 8px 20px !important;
    }

    .participantsSearchList {
        padding: 0 5px !important;
        height: calc(100vh - 425px) !important;
    }

    td.sendButtonWidget {
        width: 62px;
    }

    td.addAttachmentWidget {
        width: 58px;
    }

    td.chatHeader, td.participantsTitle {
        height: 52px;
    }

    td.participantDetailRight {
        padding: 0 15px 0 0 !important;
    }

    /*td.participantsTitle .Content {
    }*/

    div.chatHeaderText {
        font-size: 15px
    }

    td.chatFooter, td.chatFooter table {
        height: 72px;
    }

    a.ParticipantClose {
        display: block;
    }

    button.ParticipantClose{
        display: block;
    }


    .table td.addAttachmentWidget, .table td.messageTypeWidget, .table td.sendButtonWidget {
        height: 72px !important;
        padding: 5px 3px !important;
    }

    textarea.msgTextArea {
        max-height: 60px !important;
    }

    td.sendButtonWidget button.btn {
        padding: 6px 0 6px 42px !important;
        width: 42px;
        height: 41px;
        border-radius: 50%;
    }

    a.chatBackButton {
        margin: 0 38px 0 0;
    }

      span.chatBackButton{
        margin: 0 38px 0 0;
    }

    a.chatBackButton:after {
        content: "";
        width: 30px;
        height: 53px;
        border-bottom: 1px solid #ba85d6;
        -webkit-transform: translateY(-28px) translateX(5px) rotate(-55deg);
        -moz-transform: translateY(-28px) translateX(5px) rotate(-55deg);
        -ms-transform: translateY(-28px) translateX(5px) rotate(-55deg);
        -o-transform: translateY(-28px) translateX(5px) rotate(-55deg);
        transform: translateY(-28px) translateX(5px) rotate(-55deg);
        position: absolute;
    }

    .editButton {
        margin-right: 15px;
    }

    div.editTitleWidgetEdit form {
        height: 24px;
    }

    nav.navbarProfile {
        padding: 10px 20px;
    }

    li.navIconWidget {
        padding: 10px 5px;
        margin: 0;
    }

    li.navIconWidget:before {
        display: none;
    }

    ul.navIconContainer {
        flex-direction: row;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        justify-content: space-around;
        -webkit-justify-content: space-around;
    }

    ul.SearchWidget {
        margin: 10px 0;
    }

    /*************************************************************************************************************************/
    .SPTitleText{
        font-size: 18px;
    }

    .SPTitleText .icon-back:before{
        height: 18px;
        width: 18px;
        bottom: 15px;
    }

    .card.SPDetails {
        flex-wrap: wrap;
    }

    .mainWidgetOverflow {
        margin-top: 40px;
    }

    .SPDetails .SPNameWidget {
        width: 100%;
        padding: 10px 20px 20px 20px;
    }

    .SPNameWidget .d-flex{
        flex-direction: column;
    }

    .SPNameWidget .d-flex .col{
        flex-basis: auto;
        margin: 0 0 10px 0;
    }

    .SPDetails .SPdpWidget {
        width: 100%;
        padding: 20px 0 0 0;
    }

    .SPRating{
        position: absolute;
        top: 0;
        left: 25px;
        background-color: #ffffff;
    }

    .SPName{
        font-size: 24px;
    }

    .SPName, .SPsubTitle, .SPNameWidget .d-flex .col{
        text-align: center;
    }

    .ratePerHour{
        margin: 0 auto;
        padding: 0 10px;
    }

    .ratePerHour span{
        margin: 0;
        font-size: 20px;
    }

    .SPDetails .SPAddressWidget {
        width: 100%;
    }

    .SPAddressWidget{
        display: flex;
        flex-direction: column;
    }

    .SPAddressContent {
        width: 100%;
    }

    .SPIconServices{
        width: 43.33%;
        margin: 0 10px 15px;
    }

    .editSkills{
        margin-top: 20px;
    }

    .SPCardTitle{
        padding: 15px;
    }

    .SPCardTitle h4{
        font-size: 18px;
    }

    .SPIconLarge:before{
        width: 22px;
        height: 22px;
    }

    .SPSkillsList{
        padding: 0 15px 15px;
    }

    #map{
        margin: 0 15px 15px;
    }

    .AvailabilityWidget .SPAvailWidget .Available, .AvailabilityWidget .SPAvailWidget .trueAvailable{
        width: 46.75%;
        margin: 0 5px 10px;
    }

    .modal-body .SPAvailWidget{
        height: auto;
        flex-direction: row;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
    }

    .SPAvailContainer {
        margin: 0 10px 15px;
    }

    .SPCertificateList{
        padding: 0 15px;
    }

    .SPTabHeader{
        padding: 0 15px;
    }

    .SPTabTitle{
        padding: 15px 25px 15px 0;
    }

    .SPTabTitle:after{
        right: 16px;
    }

    .SPTabTitleContent{
        font-size: 16px;
    }

    .SPIconServices span {
        font-size: 14px;
    }

    .SPServiceCount {
        height: 18px;
        width: 18px;
        line-height: 16px;
        font-size: 12px;
    }

    .SPTabWidget div.SPTabContent{
        padding: 15px 15px 0;
    }

    .SPSCertificateItems{
        padding: 15px 5px 15px 40px;
    }

    .SPWorkHistoryItems{
        padding: 15px 5px 15px 40px;
    }

    .SPEducationItems{
        padding: 15px 5px 15px 40px;
    }

    .SPCertificateContent .d-flex, .SPAvailWidget{
        flex-direction: column;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
    }

    .SPCertificateHeader{
        font-size: 15px;
    }

    .SPEducationItems:before, .SPSCertificateItems:before, .SPWorkHistoryItems:before{
        width: 30px;
        height: 30px;
        background-size: 20px auto;
    }

    .SPDetailsContainer{
        border-left: 0;
    }

    .SPAddressContent:first-child {
        margin-right: 10px;
        margin-bottom: 15px;
    }

    .SPAvailWidget{
        height: 210px;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .d-flex .SPWorkYear {
        margin: 0 0 5px 0 !important;
        text-align: left;
        font-size: 14px;
        color: #7a329f;
    }

    div.editProfileImageContainer, div.editProfileDetailsContainer{
        flex: 0 0 100%;
        max-width: 100%;
    }

    .UploadedImage{
        max-height: 250px;
    }

    .CertificationModal .modal-content .modal-body, .editSkillsModal .modal-content .modal-body, .educationModal .modal-content .modal-body, .LanguagesModal .modal-content .modal-body, .WorkHistoryModal .modal-content .modal-body{
        padding: 0 20px;
    }

    div.asyncModalHeader{
        padding: 14px !important;
    }

    .SPGuardianItems{
        width: 100%;
    }

    div.SPContactContent{
        margin: 0 !important;
        text-align: center;
    }

    .PrimaryAddress{
        position: absolute;
        background-color: #ffffff;
        right: 0;
        width: 1px;
        top: 0;
        height: 1px;
        text-indent: -9999px;
    }
    .PrimaryAddress:before{
        content: 'a';
        position: absolute;
        color: #ffffff;
        background-color: #7a329f;
        right: 10px;
        width: 18px;
        top: 4px;
        height: 18px;
    }

    @media (max-width: 480px) {
        td.chatMsgContent .Content {
            height: calc(100vh - 304px);
        }
    
        .chatContainerWrapper {
            height: calc(100vh - 160px);
        }
    }

    .mainWidget .MemberDetails{
        min-height: calc(100vh - 210px) !important;
        margin-top: 140px;
        height: calc(100vh - 210px) !important;
    }

    div.mainWidget .MemberDetails .mainContent{
        min-height: calc(100vh - 240px) !important;
        margin-top: 0;
        height: auto !important;
    }

}

@media (max-height: 480px) {
    .nav-item p{
        margin-bottom: 5px;
        font-size: 14px;
    }

    .nav-item h5{
        font-size: 18px;
    }

    .noMessage {
        height: calc(100vh - 315px);
    }

    .participantsList .Content {
        height: calc(100vh - 275px);
        padding: 0 !important;
    }

    .msgSectionWrapper .mainWidgetProfile{
        padding: 0;
    }

}

@media only screen 
and (min-height: 320px) 
and (max-width: 812px) 
and (orientation: landscape) { 
  td.chatMsgContent .Content{
      width:100%;
      height: calc( 100vh - 245px );
      
  } 
}

/*bug-fixing-for-iphone-&-other-mobile*/
@media only screen and (max-width: 820px) {
    div.sideContent {
        width: 100%;
        z-index: 10000;
        clear: both;
    }

    .onBoarding .mainWidget .mainContent {
        min-height: calc(100vh - 210px) !important;
        margin-top: 140px;
        padding: 0;
        height: calc(100vh - 210px) !important;
    }

    .mainWidget .MemberDetails .mainContent{
        height: auto !important;
        margin: 0;
    }

    .onBoarding .mainWidget .mainContent .scrollarea-content 
    {
        padding: 0 15px;
        overflow-y: auto;
    }

    .onBoarding .mainWidget .mainContent .d-inline-block .selectType
    {
        height: 180px;
        width: 180px;
        margin-top: 25%;
    }

    .onBoarding .mainWidget .mainContent .d-inline-block .selectType label:after
    {
        top: 15px;
    }

    .onBoarding .mainWidget .mainContent .d-inline-block .selectType label span small
    {
      font-size:12px;
    }

    .onBoarding .mainContent .form
    {
    
    }
}

@media only screen and (max-width: 767px) {
    
}

@media only screen and (max-width: 480px) {
    .onBoarding .mainWidget .mainContent .d-inline-block .selectType
    {
        margin-top: 20% !important;
    }

}