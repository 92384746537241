
    
    body, html { 
      font-family: 'Brush Script MT', cursive;
    }
    .backgroundimage {
        width: 360px;
        height: 640px;
        align-items:'center';
    }
    .titleView{
        flex: 1,
    }
    .content{
        flex: 6;
        justify-content:'flex-start';
    }
    .childComponent{
        justify-content: 'center';
        align-items: 'center';
    }
    .account{
        margin-bottom: 7px;
    }
    
    .title{
        flex: 4;
        margin-top: setHeight(12.03);
        align-items: "center";
        justify-content: "center";
        position: "absolute";
        width: 116;
        height: 200;
        left: 106;
        top: 122;

 /* identical to box height */

    color: "#A3A375"
    }
    .subtitle{
        font-size: 3vw;
        color: rgb(233, 25, 153);
        align-items: "center";
        justify-content: "center";
        margin-left:100;
        font-family: 'Playfair Display', cursive;
     }
    .shoptitle{
     
      margin-right:5px;
      margin-left:5px;
       font-size: 2vw;
       font-weight: '300';
       color: rgb(233, 25, 153);
       align-items: "center";
       justify-content: "center";
     }
    .subcattitle{
        margin-top: 2px;
        margin-Left:4;
        font-size: 1vw;
         color: rgb(233, 25, 153);
         align-items: "center";
         justify-content: "center";
         font-family: 'Playfair Display', cursive;
   
         
     }
     .description{
      position: "absolute";
      margin-top: 4px;
      margin-Left:4;
      font-size: 1vw;
      color: rgb(233, 25, 153);
      align-items: "center";
      justify-content: "center";
      font-family: 'Cormorant';
      padding-left: 5.27px;
  }
    /* .Ellipse {
        position: "absolute";
        width: 220px;
        height: 220px;
        background-color: #FFCC00;
        left: 100;
        top: 700;
        border-radius:220/2;
      
      } */
      .Ellipse2 {
        position: "absolute";
        width: 200px;
        height: 200px;
        background-Color: #008AE6;
        left: -10;
        top: -70;
        border-radius: 100px;
       
     
      }
    
    .padding {
        padding-left: 5.27px;
        padding-right: 5.27px,
    }
    .button {
        justify-content: 'center';
        background-color: #c413b5;
        height: 51px;
        border-radius:10px;
        margin-bottom: 23px;
        width: 320px;
        align-self: "center";
        padding-top:10px;
    }
    .semibold {
        font-weight: '600',
    }
    .login{
        margin-top: 3.59px;
    }
    .loginfont {
        font-size: 2.3px;
        align-self:'center';
    }
    .logo{
        width: 360px;
        height: 360px;
        margin-top: 55px;
        align-self: "center";
        margin-bottom: 255px;
    }
    .buttonsContainer {
        position: "absolute";
        align-items: "center";
        justify-content: "center";
        align-content: "center";
        margin-top: 490px;
    }
    .mainContainerStyle{
        align-items:'center';
    }
    .message{
        font-size: 3vw;
    } 
    .slide {
        width: 360px;
        height: 640px;
     
    }
    .slideInnerContainer {
        width: 360px;
        flex: 1
        
    }
    .search{
        flex-Direction:'row-reverse';
        width:'90%';
        height:40;
        align-items:'center';
   
      } 
      .SquareShapeView {
         width: 100;
        height: 40;
        background-color: '#000';
        border-style:'solid';
        border-width:2;
        border-color:'#fff';
        border-radius:5;
        padding-right:5;
        margin-right: 5;
        align-items:'center';
        font-size:3vw;
       
      } 
      .flcontainer {
        margin-top: 5;
        margin-right: 20;
        padding-left:5;
        padding-top:7;
        align-self: "auto";
        overflow: 'scroll';
        height:50px;
        background-Color: 'white';
        border-width:5;
        border-color:'#a3a375';
        width:340;
        border-Width: 0;
      }
      
      .flProductContainer{
        margin-top: 10;
        margin-right: 20;
        align-self: "auto";
        overflow: 'scroll';
        height:450px;
      
       width: auto;
        border-width: 0;
        /* Small Devices, Tablets */
 
      }
      .row {

        border-radius: 2;
        background-color: '#ffffff';
        flex: 1;
        flex-direction: 'row';
        justify-content: 'flex-start';
        align-items: 'center';
        width:auto;
        
        @media only screen and (min-width : 768px) {
          width: 75%;
          
        }
      
        /* Medium Devices, Desktops */
        @media only screen and (min-width : 992px) {
          width: 50%;
          
        }
      }
      .row_cell_timeplace {
        flex: 1;
        flex-direction: 'column',

      }
      .row_cell_temp {
        color: 'black';
        font-size:3vw;
        flex: 1 ;

        @media only screen and (min-width : 768px) {
          width: 75%;
          
        }
      
        /* Medium Devices, Desktops */
        @media only screen and (min-width : 992px) {
          width: 50%;
          
        }
      }
      .row_time {
        color:'#ffcc00' ;
        text-align: 'bottom';
        flex: 0 1 200px;
        font-size: 3vw;
        align-items: flex-start;
        width:auto;
        @media only screen and (min-width : 768px) {
          width: 75%;
          
        }
      
        /* Medium Devices, Desktops */
        @media only screen and (min-width : 992px) {
          width: 50%;
          
        }
       
      }
      .row_place {
        color:'#003300' ;
        text-align: 'top';
        flex: 0;
        font-size: 3vw;
       }
      .headerrow {
        background-color: '#fff';
        flex-Direction: 'row';  
        justify-content:'flex-start'; 
        align-items: 'center';
        padding-top: 5;
        padding-bottom: 5;
        padding-right: 10;
        padding-right: 10;
        margin-left: 5;
        margin-right: 10;
        margin-top: 0;
        margin-bottom: 2;

        @media (max-width: 1000px) {
          grid-template-columns: repeat(auto-fill, 
                                 minmax(200px, 1fr));
        }
      }
     
      .hdrow_cell_temp {
        color: '#ffffff';
        padding-left: 10;
        flex:  0 0 0;
        font-size:3vw,

      }
      /* .scrollView {
       
      
   
        
      } */
      .circle {
        width: 16;
        height: 16;
        left:18;
        top:10;
        position: 'absolute';

        color:'black';
    
      }
         .overlay {
        flex: 1;
        position: 'absolute';
        left: 0;
        top: 0;
        opacity: 0.5;

        width:100px;
      } 
      .containermodel {
    
        flex: 1;
        height:400px;
        margin-top:0;
      }
      .divsidebar
      {
        background-color: #ffff;
        width: 10%;
        padding-top: 10PX;
        /* overflow: auto; */
        height: 70vh;
        /* margin-right: 1px; */
        border-bottom: 10em;
        border-color: rgb(247, 233, 42);
        text-align: center;
        /* line-height: 75px; */
        font-size: 3vw;
        @media (max-width: 1000px) {
          grid-template-columns: repeat(auto-fill, 
                                 minmax(200px, 1fr));
        }
      }