.popup {
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  overflow: scroll;
  height: 100vh;
  background-color: rgb(255 255 255);
  /* display: flex; */
  border: 0px solid #ccc;
}
.popup-inner{
 position:relative;    width: 60%;
  margin: 4% auto;overflow-y: scroll;
  height: 70vh;
}


.btnclose {
  position: absolute;
  right: 0;
  top: 0px;
  margin: 0;
}
.formdata {
  padding: 5%;
  margin: auto;
  width: 90%;
}

.checkposition {
  top: 4px;
  position: relative;
}
.selected-id-box {
  background-color: rgb(220, 220, 220);
  border-radius: 5px;
  padding: 2px 10px;
  margin: 5px 5px;
}
.selected-id-box .label {
  padding: 0;
  margin: 0;
  font-weight: normal;
  font-size: 15px;
}
.searchBox {
  position: relative;
}
.searchBox .optionsBlock {
  position: absolute;
  top: 35px;
  left: 0px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 10px 5px;
  background-color: white;
  width: 100%;
}
.searchBox .options {
  /* border-bottom-width: 0.5px;
  border-bottom-style: solid; */
  /* border-color: rgb(205, 205, 205); */
  padding: 0px 5px;
}
