.subject-info-box-1, .subject-info-box-2 {
    float: left;
    width: 45%;
}
.subject-info-box-1 select, .subject-info-box-2 select {
    height: 200px;
    padding: 0;
}
.subject-info-box-1 select option, .subject-info-box-2 select option {
    padding: 4px 10px 4px 10px;
}
.subject-info-box-1 select option:hover, .subject-info-box-2 select option:hover {
    background: #eee;
}
.subject-info-arrows {
    float: left;
    width: 10%;
}
.subject-info-arrows input {
    width: 70%;
    margin-bottom: 5px;
}
